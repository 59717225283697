import {
  IMoosaDataService,
  ITableConfigSubRoot,
  ColumnId,
  Expression,
} from '@discngine/moosa-models';
import { Action, ThunkAction } from '@reduxjs/toolkit';

import {
  selectTableColumnIds,
  selectTableColumnsMap,
  selectTableId,
  ComputedColumnsChanges,
  ITableInfoSubRoot,
} from '@discngine/moosa-store/tableInfo';

import {
  PINNED_COLUMNS,
  selectComputedColEditing,
  selectTableColumnIsComputedFlags,
  selectTableColumnOrder,
  selectTableColumnTypes,
  selectTableColumnVisibility,
  selectUseTemplateColumns,
} from './tableConfig.selectors';
import { overwriteColumnOrderAndVisibility } from './tableConfig.slice';

type TableConfigThunk<T = void> = ThunkAction<
  T,
  ITableConfigSubRoot & ITableInfoSubRoot,
  unknown,
  Action<string>
>;

export function setColumnsFromTemplate(
  getTemplateColumnsOrder: () => ColumnId[]
): TableConfigThunk {
  return (dispatch, getState) => {
    const state = getState();
    const useTemplateColumns = selectUseTemplateColumns(state);
    const templateColumnsOrder = getTemplateColumnsOrder();

    if (!useTemplateColumns) {
      return;
    }

    const tableId = selectTableId(state);
    const types = selectTableColumnTypes(state);
    // const templateColumnsOrder = selectScoringColumnOrder(state);
    const tableOrder = selectTableColumnOrder(state);
    const pinned = tableOrder.filter((col) => PINNED_COLUMNS.includes(types[col]));
    const fromTemplate = templateColumnsOrder.filter((col) => types[col]);
    const fromDataset = tableOrder
      .filter((col) => !pinned.includes(col))
      .filter((col) => !fromTemplate.includes(col));

    const newTableOrder = [...pinned, ...fromTemplate, ...fromDataset];

    const visibility = selectTableColumnVisibility(state);
    const newVisibility = { ...visibility };

    fromTemplate.forEach((colId) => (newVisibility[colId] = true));
    fromDataset.forEach((colId) => (newVisibility[colId] = false));

    dispatch(
      overwriteColumnOrderAndVisibility({
        tableId,
        order: newTableOrder,
        visibility: newVisibility,
      })
    );
  };
}

export function updateComputedColumns(
  service: {
    patchComputedColumns: IMoosaDataService['patchComputedColumns'];
    getDatasetMetadata: IMoosaDataService['getDatasetMetadata'];
    getDataWithScore: IMoosaDataService['getDataWithScore'];
  },
  columnId: string,
  expression: Expression,
  onAfterUpdate: (
    tableId: string,
    computedColumnsChanges: ComputedColumnsChanges
  ) => Promise<void>
): TableConfigThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const tableId = selectTableId(state);
    const editedColumnId = selectComputedColEditing(state);
    const newColumn = editedColumnId === null;

    const ids = selectTableColumnIds(state);
    const columns = selectTableColumnsMap(state);

    const computedColumns = selectTableColumnIsComputedFlags(state);
    const computed = ids
      .filter((id) => computedColumns[id])
      .map((id) => {
        if (editedColumnId === id) {
          return { name: columnId, expression };
        }

        return { name: id, expression: columns[id].formula!.expression };
      });

    if (newColumn) {
      computed.push({ name: columnId, expression });
    }

    await service.patchComputedColumns(tableId, computed);

    const computedColumnsChanges: ComputedColumnsChanges = {
      colAdded: newColumn ? columnId : undefined,
      colUpdated: newColumn
        ? undefined
        : {
            from: editedColumnId!,
            to: columnId,
          },
    };

    await onAfterUpdate(tableId, computedColumnsChanges);
  };
}

export function deleteEditedComputedColumn(
  service: {
    patchComputedColumns: IMoosaDataService['patchComputedColumns'];
    getDatasetMetadata: IMoosaDataService['getDatasetMetadata'];
    getDataWithScore: IMoosaDataService['getDataWithScore'];
  },
  onAfterDelete: (tableId: string, editedColumnId: string | null) => Promise<void>
): TableConfigThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const tableId = selectTableId(state);
    const editedColumnId = selectComputedColEditing(state);

    const ids = selectTableColumnIds(state);
    const columns = selectTableColumnsMap(state);

    const computedColumns = selectTableColumnIsComputedFlags(state);
    const computed = ids
      .filter((id) => computedColumns[id] && id !== editedColumnId)
      .map((id) => ({ name: id, expression: columns[id].formula!.expression }));

    await service.patchComputedColumns(tableId, computed);

    await onAfterDelete(tableId, editedColumnId);
  };
}
