import { IColumnMetaInfo, IDiscretePoint } from '@discngine/moosa-models';
import { FieldType, SortFunctionTableType } from '@discngine/moosa-models';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useComponentsContext } from '../../../ComponentsContext/ComponentsContext';
import { SortAscIcon, SortDscIcon, PlusIcon } from '../../../icons';

import AddValue from './AddValue/AddValue';
import styles from './DiscreteFunctionTableStyles.module.less';
import { DeletePointModal } from './DiscreteValuesPointEditor/DeletePointModal/DeletePointModal';
import { DiscreteValuesPointEditor } from './DiscreteValuesPointEditor/DiscreteValuesPointEditor';

interface DiscreteFunctionTableProps {
  className?: string;
  metadata: IColumnMetaInfo;
  points: IDiscretePoint[];
  onFunctionPointsChange(points: IDiscretePoint[]): void;
  onRemoveFunctionPoint(index: number): void;
  onToggleHistogramVisible(): void;
  onAddFunctionPoint: (param: {
    valueX: number | string;
    valueY: number;
    xSort: SortFunctionTableType;
    ySort: SortFunctionTableType;
    originalPointIndex?: number;
    fieldType?: FieldType;
  }) => void;
  isHistogramVisible: boolean;
  column: string;
  onPointsOrderChange: (
    columnName: string,
    fromPoint: string | number,
    toPoint: string | number
  ) => void;
}

const DiscreteFunctionTable: FC<DiscreteFunctionTableProps> = React.memo(
  function DiscreteFunctionTable({
    className,
    points,
    onFunctionPointsChange,
    metadata,
    onRemoveFunctionPoint,
    onToggleHistogramVisible,
    isHistogramVisible,
    onAddFunctionPoint,
    onPointsOrderChange,
  }) {
    const { Button, Col, Popover, Row, Tooltip } = useComponentsContext();

    const [isAddValueModalVisible, setIsAddValueModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [pointIndex, setPointIndex] = useState(0);

    const isXAscending = useCallback(
      (pointsArr: IDiscretePoint[]) => {
        if (metadata.isDiscreteColumn && metadata.type === FieldType.Number) {
          return pointsArr.every((point, i) => i === 0 || point.x >= pointsArr[i - 1].x);
        } else if (metadata.isDiscreteColumn && metadata.type === FieldType.String) {
          return pointsArr.every((point, i) => {
            return (
              i === 0 ||
              point.x.toString().toLowerCase() >
                pointsArr[i - 1].x.toString().toLowerCase()
            );
          });
        }
      },
      [metadata.isDiscreteColumn, metadata.type]
    );

    const isXDescending = useCallback(
      (pointsArr: IDiscretePoint[]) => {
        if (metadata.isDiscreteColumn && metadata.type === FieldType.Number) {
          return pointsArr.every((point, i) => i === 0 || point.x <= pointsArr[i - 1].x);
        } else if (metadata.isDiscreteColumn && metadata.type === FieldType.String) {
          return pointsArr.every(
            (point, i) =>
              i === 0 ||
              point.x.toString().toLowerCase() <
                pointsArr[i - 1].x.toString().toLowerCase()
          );
        }
      },
      [metadata.isDiscreteColumn, metadata.type]
    );

    const isYAscending = useCallback((pointsArr: IDiscretePoint[]) => {
      return pointsArr.every(
        (point, i) =>
          (i === 0 || point.y >= pointsArr[i - 1].y) &&
          !pointsArr.every((pointSecond) => pointSecond.y === pointsArr[0].y)
      );
    }, []);

    const isYDescending = useCallback((pointsArr: IDiscretePoint[]) => {
      return pointsArr.every(
        (point, i) =>
          (i === 0 || point.y <= pointsArr[i - 1].y) &&
          !pointsArr.every((pointSecond) => pointSecond.y === pointsArr[0].y)
      );
    }, []);

    const { xSort, ySort } = useMemo(() => {
      let xSort;
      let ySort;

      if (isXAscending(points)) {
        xSort = SortFunctionTableType.Ascending;
      } else if (isXDescending(points)) {
        xSort = SortFunctionTableType.Descending;
      } else {
        xSort = SortFunctionTableType.None;
      }

      if (isYAscending(points)) {
        ySort = SortFunctionTableType.Ascending;
      } else if (isYDescending(points)) {
        ySort = SortFunctionTableType.Descending;
      } else {
        ySort = SortFunctionTableType.None;
      }

      return {
        xSort,
        ySort,
      };
    }, [isXAscending, isXDescending, isYAscending, isYDescending, points]);

    const onShowDeletePointModal = useCallback((pointIndex: number) => {
      setPointIndex(pointIndex);
      setIsDeleteModalVisible(true);
    }, []);

    const onDelete = useCallback(() => {
      onRemoveFunctionPoint(pointIndex);
      setIsDeleteModalVisible(false);
    }, [pointIndex, onRemoveFunctionPoint]);

    const onCancel = useCallback(() => {
      setIsDeleteModalVisible(false);
    }, []);

    const onToggleAddValueModal = useCallback((isVisible: boolean) => {
      setIsAddValueModalVisible(isVisible);
    }, []);

    const handleVisibleChange = useCallback((visible: boolean) => {
      setIsAddValueModalVisible(visible);
    }, []);

    const onPointChange = useCallback(
      (index: number) => (point: IDiscretePoint) => {
        const newPoints = [...points];

        newPoints[index] = { ...point, originalPointIndex: index };
        onFunctionPointsChange(newPoints);
      },
      [points, onFunctionPointsChange]
    );

    const onYSortPoints = useCallback(
      (sort) => {
        const newPoints = [...points].sort((point, nextPoint) => {
          if (sort === SortFunctionTableType.Ascending) {
            return Number(point.y) - Number(nextPoint.y);
          } else if (sort === SortFunctionTableType.Descending) {
            return Number(nextPoint.y) - Number(point.y);
          } else {
            return 0;
          }
        });

        onFunctionPointsChange(newPoints);
      },
      [onFunctionPointsChange, points]
    );

    const onXSortPoints = useCallback(
      (sort) => {
        if (metadata.isDiscreteColumn && metadata.type === FieldType.Number) {
          const sortedPoints = [...points].sort((point, nextPoint) => {
            if (sort === SortFunctionTableType.Ascending) {
              return Number(point.x) - Number(nextPoint.x);
            } else if (sort === SortFunctionTableType.Descending) {
              return Number(nextPoint.x) - Number(point.x);
            } else {
              return 0;
            }
          });

          onFunctionPointsChange(sortedPoints);
        } else {
          if (metadata.isDiscreteColumn && metadata.type === FieldType.String) {
            const sortedPoints = [...points].sort(function (point, nextPoint) {
              const nameA = point.x.toString().toLowerCase();
              const nameB = nextPoint.x.toString().toLowerCase();

              if (sort === SortFunctionTableType.Ascending) {
                if (nameA < nameB) {
                  return -1;
                }

                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              } else if (sort === SortFunctionTableType.Descending) {
                if (nameA > nameB) {
                  return -1;
                }

                if (nameA < nameB) {
                  return 1;
                }

                return 0;
              } else {
                return 0;
              }
            });

            onFunctionPointsChange(sortedPoints);
          }
        }
      },
      [metadata.isDiscreteColumn, metadata.type, onFunctionPointsChange, points]
    );
    const sortXColumn = useMemo(() => {
      if (metadata.isDiscreteColumn && metadata.type === FieldType.Number) {
        if (xSort === SortFunctionTableType.Ascending) {
          return (
            <SortAscIcon
              className={styles.sortIcon}
              onClick={() => onXSortPoints(SortFunctionTableType.Descending)}
            />
          );
        } else if (xSort === SortFunctionTableType.Descending) {
          return (
            <SortDscIcon
              className={styles.sortIcon}
              onClick={() => onXSortPoints(SortFunctionTableType.Ascending)}
            />
          );
        } else {
          return (
            <SortAscIcon
              className={`${styles.sortIcon} ${styles.notSorted}`}
              onClick={() => onXSortPoints(SortFunctionTableType.Descending)}
            />
          );
        }
      } else if (metadata.isDiscreteColumn && metadata.type === FieldType.String) {
        if (xSort === SortFunctionTableType.Ascending) {
          return (
            <SortAscIcon
              className={styles.sortIcon}
              onClick={() => onXSortPoints(SortFunctionTableType.Descending)}
            />
          );
        } else if (xSort === SortFunctionTableType.Descending) {
          return (
            <SortAscIcon
              className={styles.sortIcon}
              onClick={() => onXSortPoints(SortFunctionTableType.Ascending)}
            />
          );
        } else {
          return (
            <SortAscIcon
              className={`${styles.sortIcon} ${styles.notSorted}`}
              onClick={() => onXSortPoints(SortFunctionTableType.Descending)}
            />
          );
        }
      }
    }, [metadata.isDiscreteColumn, metadata.type, onXSortPoints, xSort]);

    const inputType = metadata.type === FieldType.String ? 'text' : 'number';
    const isAddValueDisabled = points.length >= 12;

    const sortYColumn = useMemo(() => {
      if (ySort === SortFunctionTableType.Ascending) {
        return (
          <SortAscIcon
            className={styles.sortIcon}
            onClick={() => onYSortPoints(SortFunctionTableType.Descending)}
          />
        );
      } else if (ySort === SortFunctionTableType.Descending) {
        return (
          <SortDscIcon
            className={styles.sortIcon}
            onClick={() => onYSortPoints(SortFunctionTableType.Ascending)}
          />
        );
      } else if (ySort === SortFunctionTableType.None) {
        return (
          <SortAscIcon
            className={`${styles.sortIcon} ${styles.notSorted}`}
            onClick={() => onYSortPoints(SortFunctionTableType.Ascending)}
          />
        );
      }
    }, [onYSortPoints, ySort]);

    return (
      <div className={className}>
        <Col>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Row align={'bottom'} gutter={[6, 0]}>
                <Col>X</Col>
                <Col>
                  <Row align={'bottom'}>{sortXColumn}</Row>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row align={'bottom'} gutter={[6, 0]}>
                <Col>f(X)</Col>
                <Col>
                  <Row align={'bottom'}>{sortYColumn}</Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={styles.tableBody}>
            {points?.map((point: IDiscretePoint, index: number) => (
              <DiscreteValuesPointEditor
                key={index}
                columnName={metadata.name}
                columnType={metadata.type}
                point={point!}
                onPointChange={onPointChange(index)}
                onPointsOrderChange={onPointsOrderChange}
                onShowModal={onShowDeletePointModal}
              />
            ))}
          </div>
        </Col>

        <div className={styles.buttons}>
          <Tooltip
            placement="right"
            title={
              isAddValueDisabled ? 'Maximum number of discrete values is exceeded' : ''
            }
          >
            <Popover
              content={
                <AddValue
                  addFunctionPoint={onAddFunctionPoint}
                  inputType={inputType}
                  points={points}
                  xSort={xSort}
                  ySort={ySort}
                  onToggleAddValueModal={onToggleAddValueModal}
                />
              }
              open={isAddValueModalVisible}
              placement="bottomRight"
              trigger="click"
              onOpenChange={handleVisibleChange}
            >
              <Button disabled={isAddValueDisabled}>
                <PlusIcon />
                <span>Add Value</span>
              </Button>
            </Popover>
          </Tooltip>

          <Button className={styles.underlined} onClick={onToggleHistogramVisible}>
            {`${isHistogramVisible ? 'Hide' : 'Show'}`} histogram
          </Button>
        </div>

        <DeletePointModal
          isDeleteModalVisible={isDeleteModalVisible}
          onCancel={onCancel}
          onDelete={onDelete}
        />
      </div>
    );
  }
);

export default DiscreteFunctionTable;
