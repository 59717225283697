import { generateGradientString, IRGBColor } from '@discngine/moosa-common';
import { toPrecisionString } from '@discngine/moosa-histogram';
import { IGradientColor } from '@discngine/moosa-models';
import round from 'lodash/round';
import { FC, useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';

import { ColorPointer } from './ColorPointer/ColorPointer';
import { IAvailableColorsPanel } from './ColorPointer/IAvailableColorsPanel';
import styles from './GradientScale.module.less';

const increaseLastSignificantDigit = (sigMax: number): number => {
  const lastSignificantDigit: number = Number(
    sigMax.toString().split('')[sigMax.toString().length - 1]
  );

  if (lastSignificantDigit === 9) {
    const secondLastSignificantDigit: number = Number(
      sigMax.toString().split('')[sigMax.toString().length - 2]
    );

    return Number(sigMax.toString().slice(0, -2) + `${secondLastSignificantDigit + 1}`);
  } else {
    const increasedMax = Number(
      sigMax.toString().slice(0, -1) + `${lastSignificantDigit + 1}`
    );

    return increasedMax;
  }
};

const getNumberOfSignificantDigits = (value: number): number => {
  return value.toExponential().replace(/^([0-9]+)\.?([0-9]+)?e[+\-0-9]*$/g, '$1$2')
    .length;
};

const toNumOfSignificantDigits = (num: number): number => {
  const significantN = getNumberOfSignificantDigits(num);

  return Number(num.toPrecision(significantN));
};

export interface IGradientScaleProps {
  onGradientChange: (gradient: IGradientColor[]) => void;
  gradient: IGradientColor[];
  range: { min: number; max: number };
  isScoredColorizingMode: boolean;
  AvailableColorsPanel: React.ComponentType<IAvailableColorsPanel>;
}

/**
 * You need to use this component with a CSS class wrapper that provides the following css variables:
 * --gradient-scale-border-color
 * --gradient-scale-background-color
 * --color-pointer-input-border-color
 * --color-pointer-input-color
 * --color-pointer-input-focus-visible-color
 *
 * Example: colorizingPanelColors class in ColorizingPanel (moosa-shared-components)
 **/

/**
 * TODO must be rewritten to use absolute values in the color gradient
 */
export const GradientScale: FC<IGradientScaleProps> = ({
  onGradientChange,
  gradient,
  range,
  isScoredColorizingMode,
  AvailableColorsPanel,
}) => {
  const [gradientScaleWidth, setGradientScaleWidth] = useState(0);
  const [newColorPointerLeft, setNewColorPointerLeft] = useState(0);
  const [activePointIndex, setActivePointIndex] = useState(-1);
  const [lastActivePointIndex, setLastActivePointIndex] = useState(-1);
  const [withInputVisiblePointIndex, setWithInputVisiblePointIndex] = useState(-1);
  const [isClick, setIsClick] = useState(true);
  const [gradientBackground, setGradientBackground] =
    useState<IGradientColor[]>(gradient);

  const rootElementRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const gradientRef = useRef<HTMLDivElement>(null);

  const isPossibleToRemoveColor = gradientBackground.length > 2;

  // TODO need to fix any
  const handleMouseMove = useCallback((event: any) => {
    const newX =
      event.clientX -
      event.currentTarget.offsetParent.offsetLeft -
      event.target.offsetLeft;

    setNewColorPointerLeft(newX);
  }, []);

  const ticks = useMemo(() => {
    if (isScoredColorizingMode) {
      const scoredDataModeTicks = [0];

      for (let i = 0; i < 10; i++) {
        scoredDataModeTicks.push(round(scoredDataModeTicks[i] + 0.1, 1));
      }

      return scoredDataModeTicks.map((tick) => tick.toString());
    }

    const desiredPrecision = 5;

    let sigMin = toNumOfSignificantDigits(range.min);
    let sigMax = toNumOfSignificantDigits(range.max);

    if (sigMin.toString().length > desiredPrecision + 2 && Number.isInteger(sigMin)) {
      sigMin = Number(sigMin.toString().slice(0, 3));
      sigMax = Number(sigMax.toString().slice(0, 3));
    }

    if (sigMin === sigMax) {
      sigMax = increaseLastSignificantDigit(sigMax);
    }

    const ticks = [sigMin];

    for (let i = 0; i < 10; i++) {
      const difference = Math.abs(sigMax - sigMin);

      let precision =
        sigMin.toString().length === 1 ? desiredPrecision : sigMin.toString().length;

      if (Number.isInteger(sigMin)) {
        precision = sigMin.toString().length + 2;
      }

      ticks.push(Number((ticks[i] + difference / 10).toPrecision(precision)));
    }

    const ticksInString = ticks.map((tick) => toPrecisionString(tick, desiredPrecision));

    return ticksInString;
  }, [isScoredColorizingMode, range.max, range.min]);

  const gradientChange = useCallback(
    (leftPosition, indexOfSelectedColor, isMouseUp) => {
      const newPercent = (leftPosition / gradientScaleWidth) * 100;

      if (gradientBackground) {
        const newGradient = gradientBackground.map((item) =>
          gradientBackground.indexOf(item) === indexOfSelectedColor
            ? { ...item, percent: newPercent }
            : item
        );

        setGradientBackground([...newGradient]);

        if (isMouseUp) {
          onGradientChange([...newGradient]);
        }
      }
    },
    [gradientScaleWidth, gradientBackground, onGradientChange]
  );

  const onColorChange = useCallback(
    (newColor: IRGBColor, indexOfSelectedColor: number) => {
      const newGradient = [...gradientBackground].map((color) =>
        gradientBackground.indexOf(color) !== indexOfSelectedColor
          ? color
          : { ...color, color: newColor }
      );

      setGradientBackground([...newGradient]);
      onGradientChange([...newGradient]);
    },
    [gradientBackground, onGradientChange]
  );

  const onRemoveColor = useCallback(
    (indexOfSelectedColor: number) => {
      const newGradient = [...gradientBackground].filter(
        (color) => gradientBackground.indexOf(color) !== indexOfSelectedColor
      );

      setGradientBackground([...newGradient]);
      onGradientChange([...newGradient]);
    },
    [gradientBackground, onGradientChange]
  );

  const onAddNewColorInterval = useCallback(() => {
    const newColor = {
      color: { r: 255, g: 236, b: 61 }, // yellow color by default
      percent: (newColorPointerLeft / gradientScaleWidth) * 100,
    };
    const newGradient = [...gradientBackground, newColor];

    newGradient.sort((item, nextItem) => (item.percent > nextItem.percent ? 1 : -1));

    setGradientBackground([...newGradient]);
    onGradientChange([...newGradient]);
  }, [gradientBackground, gradientScaleWidth, newColorPointerLeft, onGradientChange]);

  const gradientString = useMemo(() => {
    let gradientString = '';

    if (gradientBackground.length === 1) {
      gradientString = `rgb(${gradientBackground[0].color})`;
    } else if (gradientBackground) {
      gradientString = generateGradientString('linear', 90, gradientBackground);
    }

    return gradientString;
  }, [gradientBackground]);

  const onMouseMove = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (activePointIndex < 0) {
        return;
      }

      const newPoints = [...gradientBackground];

      newPoints[activePointIndex] = { ...newPoints[activePointIndex] };

      newPoints[activePointIndex].percent += (event.movementX / gradientScaleWidth) * 100;

      if (newPoints[activePointIndex].percent <= 0) {
        newPoints[activePointIndex].percent = 0;
      } else if (newPoints[activePointIndex].percent > 100) {
        newPoints[activePointIndex].percent = 100;
      }

      setGradientBackground(newPoints);
    },
    [activePointIndex, gradientBackground, gradientScaleWidth]
  );

  const onTimerExpired = () => {
    setIsClick(false);
  };

  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);

  const onMouseDown = (indexOfSelectedColor: number) => {
    setActivePointIndex(indexOfSelectedColor);
    setLastActivePointIndex(indexOfSelectedColor);

    setWithInputVisiblePointIndex(indexOfSelectedColor);

    setTimerId(setTimeout(onTimerExpired, 200));
  };

  const onMouseUp = () => {
    onGradientChange(gradientBackground);
    setActivePointIndex(-1);

    if (timerId) {
      clearTimeout(timerId);
    }

    if (!isClick) {
      setWithInputVisiblePointIndex(-1);
    }
    setIsClick(true);
  };

  const onChangeInputVisibility = useCallback((indexOfSelectedColor) => {
    setWithInputVisiblePointIndex(indexOfSelectedColor);
  }, []);

  useEffect(() => {
    setGradientBackground(gradient);
  }, [gradient]);

  useEffect(() => {
    if (handleRef.current) {
      handleRef.current.style.left = `${newColorPointerLeft}px`;
    }
  }, [newColorPointerLeft]);

  useEffect(() => {
    if (gradientScaleWidth === 0 && rootElementRef.current) {
      setGradientScaleWidth(rootElementRef.current.offsetWidth);
    }
  }, [gradientScaleWidth]);

  if (!gradient) {
    return null;
  }

  return (
    <div
      ref={rootElementRef}
      className={styles.root}
      onMouseLeave={onMouseUp}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div
        className={styles.helperDiv}
        onClick={onAddNewColorInterval}
        onMouseMove={handleMouseMove}
      />
      <div className={styles.gradientScaleOverlay}>
        <div
          ref={handleRef}
          className={styles.handleWrap}
          style={{ left: `${newColorPointerLeft}px` }}
        >
          <div className={styles.handle} />
          <div className={styles.handlePointer} />
        </div>
      </div>
      <div className={styles.gradientScale}>
        <div
          ref={gradientRef}
          className={styles.gradient}
          style={{ background: gradientString }}
        >
          {gradientBackground.map((currentColor, index) => (
            <ColorPointer
              key={index}
              AvailableColorsPanel={AvailableColorsPanel}
              color={currentColor.color}
              gradientChange={gradientChange}
              gradientScaleWidth={gradientScaleWidth}
              indexOfSelectedColor={index}
              isInputVisible={index === withInputVisiblePointIndex}
              isLastActiveColorPointer={index === lastActivePointIndex}
              isOnDrag={index === activePointIndex}
              isPossibleToRemoveColor={isPossibleToRemoveColor}
              isScoredColorizingMode={isScoredColorizingMode}
              left={(currentColor.percent / 100) * gradientScaleWidth}
              range={range}
              onChangeInputVisibility={onChangeInputVisibility}
              onColorChange={onColorChange}
              onColorPointerMouseDown={onMouseDown}
              onRemoveColor={onRemoveColor}
            />
          ))}
        </div>
      </div>
      <div className={styles.scale}>
        {ticks.map((tick, index) => (
          <div
            key={index}
            className={styles.tickWrap}
            style={{ width: gradientScaleWidth / (ticks.length - 1) }}
          >
            <div
              className={`${styles.tick} ${
                !isScoredColorizingMode ? styles.datasetMode : ''
              }`}
            >
              {tick}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
