import {
  Condition,
  DesirabilityFunctionCondition,
  DiscreteCondition,
} from '@discngine/moosa-models';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleLinear, scaleUtc } from '@visx/scale';
import React, { FC, useMemo } from 'react';

import { EmptyHistogramNotification } from './EmptyHistogramNotification';
import {
  MARGIN,
  NUM_TICKS_Y,
  tickLabelProps,
  dateTickFormatter,
  SMALL_NUM_TICKS_Y,
  SMALL_NUM_TICKS_X,
  NUM_TICKS_X,
} from './histogramHelpers';
import style from './NumericHistogram.module.less';
import { NumericHistogramInput } from './NumericHistogramInput';
import { NumericSlicedBar } from './NumericSlicedBar';
import { DateHistogramData } from './types';

type DateHistogramProps = {
  data: DateHistogramData;
  width: number;
  height: number;
  condition?: Exclude<Condition, DiscreteCondition | DesirabilityFunctionCondition>;
  onChange?: (condition: Condition) => void;
  isSmall?: boolean; // Reduced view of histogram with reduced number of ticks for X and Y axes
};

export const DateHistogram: FC<DateHistogramProps> = React.memo(
  ({ data, height, width, condition, onChange, isSmall = false }) => {
    const MAX_TICKS = useMemo(
      () => (isSmall ? SMALL_NUM_TICKS_X : NUM_TICKS_X),
      [isSmall]
    );
    const yMax = height - MARGIN.bottom;
    const xMax = width - MARGIN.right;

    const yValueMax = useMemo(() => {
      return Math.max(...data.bars.map(({ y }) => y.reduce((sum, y) => sum + y, 0)));
    }, [data]);

    const xRange = useMemo(() => {
      const range = { min: Infinity, max: -Infinity, delta: 0, dataWidth: 0 };

      for (const { x } of data.bars) {
        if (x[0] < range.min) {
          range.min = x[0];
        }

        if (x[1] > range.max) {
          range.max = x[1];
        }
      }

      range.dataWidth = range.max - range.min;
      range.delta = range.dataWidth * 0.01 || range.max * 0.01;

      if (data.bars.length < 6) {
        range.delta = (range.dataWidth / data.bars.length) * ((6 - data.bars.length) / 2);
      }

      return range;
    }, [data]);

    const yScale = useMemo(() => {
      return scaleLinear({ range: [MARGIN.top, yMax], domain: [yValueMax, 0] });
    }, [yValueMax, yMax]);

    const xScale = useMemo(() => {
      return scaleUtc({
        range: [MARGIN.left, xMax],
        domain: [xRange.min - xRange.delta, xRange.max + xRange.delta],
      });
    }, [xMax, xRange]);

    const tickValues = useMemo(() => {
      if (data.bars.length < MAX_TICKS) {
        const values = data.bars.map(({ x }) => x[0]);

        values.push(data.bars[data.bars.length - 1].x[1]);

        return values;
      }
    }, [MAX_TICKS, data.bars]);

    const numTicks = data.bars.length > MAX_TICKS ? MAX_TICKS : data.bars.length + 1;

    return (
      <svg
        className={style.plot}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        {yValueMax <= 0 && <EmptyHistogramNotification height={height} width={width} />}

        {yValueMax > 0 && (
          <Group>
            <Group>
              <NumericSlicedBar
                data={data}
                numericBarGap={isSmall ? 0 : undefined}
                xScale={xScale}
                yScale={yScale}
              />
            </Group>

            <Group left={MARGIN.left}>
              <AxisLeft
                hideZero
                numTicks={isSmall ? SMALL_NUM_TICKS_Y : NUM_TICKS_Y}
                scale={yScale}
              />
            </Group>

            <Group top={yMax}>
              <AxisBottom
                numTicks={numTicks}
                scale={xScale}
                tickFormat={dateTickFormatter}
                tickLabelProps={tickLabelProps}
                tickValues={tickValues}
              />
            </Group>

            <NumericHistogramInput
              condition={condition}
              dataType={'date'}
              height={height}
              width={width}
              xScale={xScale}
              onChange={onChange}
            />
          </Group>
        )}
      </svg>
    );
  }
);
