import {
  ComponentsContext,
  IComponentsContext,
  InputProps,
  ButtonProps,
} from '@discngine/moosa-shared-components-gen';
import { Button, Col, Input, Modal, Popover, Row, Select, Tooltip } from 'antd';
import { FC, ReactNode } from 'react';

import { ValueInput } from '../ValueInput/ValueInput';

const CommonInput: FC<InputProps> = (props) => <Input {...props} />;
const CommonButton: FC<ButtonProps> = (props) => <Button {...props} />;

const context: IComponentsContext = {
  Input: CommonInput,
  ValueInput: ValueInput,
  Select: Select,
  Tooltip: Tooltip,
  Button: CommonButton,
  Popover: Popover,
  Row: Row,
  Col: Col,
  Modal: Modal,
};

/**
 * AntD implementation of ComponentsContext.
 */
export const AntComponentsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ComponentsContext.Provider value={context}>{children}</ComponentsContext.Provider>
  );
};
